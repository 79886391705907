import Button from "@material-ui/core/Button";
import deepOrange from "@material-ui/core/colors/deepOrange";
import lightBlue from "@material-ui/core/colors/lightBlue";
import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";

import Clock from "./Clock.js";

import "./App.css";

function App() {
  const theme = React.useMemo(() =>
    createMuiTheme({
      palette: {
        primary: lightBlue,
        secondary: deepOrange,
        error: red,
        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        contrastThreshold: 3,
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2
      }
    })
  );

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Button variant="contained" color="primary">
          <Clock />
        </Button>
      </div>
    </ThemeProvider>
  );
}

export default App;
